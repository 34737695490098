import styles from "./ErrorScreen.module.scss";
import { GenericsError } from "../components/generics/GenericsError";
import { GenericButton } from "../components/generics/GenericButton";

export const buttonStyle = {
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px',
    border: 'none',
    borderRadius: '3rem',
    backgroundColor: 'white',
    padding: '1rem',
    margin: '0.4rem',
    witdh: 'max-content',
};

export const ErrorScreen = ({ title, text, style }) => {
    return (
        <GenericsError title={title}
            text={text} style={style}
        >

                <GenericButton>
                    <a href="/" className={styles.link}>
                        <span><h5>
                            Volver a inicio
                        </h5></span>
                    </a>
                </GenericButton>
        </GenericsError>
    );
};
