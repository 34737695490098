import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'; 
import { AuthenticatedTemplate, useMsal } from '@azure/msal-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark, faTicket, faHouse, faUser, faCartShopping, faStar, faLink, faLocationDot, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import styles from './Navbar.module.scss';
import { useAtom } from 'jotai';
import { isVisitActiveAtom } from '../atoms/globalAtoms';


function Navbar() {
    const { instance } = useMsal();
    const [isChecked, setIsChecked] = useState(false);
    const location = useLocation();
    const [isVisitActive] = useAtom(isVisitActiveAtom);

    useEffect(() => {
        document.documentElement.style.setProperty('--active-link-color', process.env.REACT_APP_HIGHLIGHT_COLOR);
    }, []);

    const toggleCheckbox = () => {
        setIsChecked(prevState => !prevState);
    };

    const handleLogoutRedirect = () => {
        instance.logoutRedirect();
    };

    const getLinkClassName = (path) => {
        if (path === '/home' && (location.pathname === '/' || location.pathname === '/home')) {
        return styles.activeLink;
        }
        return location.pathname === path ? styles.activeLink : '';
    };

    const logoImageUrl = process.env.REACT_APP_BLOB_ROOT_FOLDER_URL + `/logo.svg`;
    const parkName = process.env.REACT_APP_PARK_NAME;

    return (
        <>
            <nav className={styles.nav}>
                <div className={styles.navInnerContainer}>
                    <div>
                        <h1 style={{backgroundImage: `url(${logoImageUrl})`}}>{parkName}</h1>
                    </div>
                </div>
            </nav>
            <AuthenticatedTemplate>
                <input type="checkbox" id="check" className={styles.check} checked={isChecked} onChange={toggleCheckbox} />
                <label htmlFor="check" className={styles.checkbuton}>
                    <span className={styles.iconNavIcon}><FontAwesomeIcon icon={faBars} /></span>
                </label>
                <div className={styles.navLinksContainer}>
                    <FontAwesomeIcon icon={faXmark} className={styles.closeNav} onClick={toggleCheckbox} />
                    <ul className={styles.navLinks}>
                        {isVisitActive && (
                            <li className={getLinkClassName('/visit')}>
                                <FontAwesomeIcon icon={faTicket} /><Link to='/visit' onClick={toggleCheckbox}>Mi visita</Link>
                            </li>
                        )}
                        <li className={getLinkClassName('/home')}>
                            <FontAwesomeIcon icon={faHouse} /><Link to='/home' onClick={toggleCheckbox}>Inicio</Link>
                        </li>   
                        <li className={getLinkClassName('/profile')}>
                            <FontAwesomeIcon icon={faUser} /><Link to='/profile' onClick={toggleCheckbox}>Mi Perfil</Link>
                        </li>
                        <li className={getLinkClassName('/orders')}>
                            <FontAwesomeIcon icon={faCartShopping} /><Link to='/orders' onClick={toggleCheckbox}>Mis Compras</Link>
                        </li>
                        <li className={styles.disabledLink}><FontAwesomeIcon icon={faStar} />Beneficios</li>
                        <li className={getLinkClassName('/infoLinks')}><FontAwesomeIcon icon={faLink} /><Link to='/infoLinks' onClick={toggleCheckbox}>Enlaces útiles</Link></li>
                        <li className={getLinkClassName('/findUs')}><FontAwesomeIcon icon={faLocationDot} /><Link to='/findUs' onClick={toggleCheckbox}> Cómo llegar</Link></li>
                        <li><FontAwesomeIcon icon={faRightFromBracket} className={styles.logout}/><button className={styles.logout} onClick={handleLogoutRedirect}>Cerrar sesión</button></li>
                    </ul>
                </div> 
            </AuthenticatedTemplate>
        </>
    );
}

export default Navbar;
