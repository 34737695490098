import styles from './Checkbox.module.scss';

export const Checkbox = ({textLabel, id, checked, description, onChange}) => {
    return (                    
        <div className={styles.checkbox}>


            <input type='checkbox' id={id} onChange={onChange} checked={checked}/>
            <span>
                <label htmlFor={id}>
                    {textLabel}
                </label>
                <p>{description}</p>
            </span>

        </div>
    )
};
