import { useEffect, useState, useRef } from "react";
import styles from './EditableField.module.scss';

export const EditableField  = ({ className, value, isEditing, onSave, isComboBox, dataComboBox }) => {
    const [currentValue, setCurrentValue] = useState(value);
    const [tempValue, setTempValue] = useState(value);
    const inputRef = useRef(null);

    const handleOnChange = (e) => {
        const newValue = e.target.value;
        setCurrentValue(newValue);
        setTempValue(newValue);
        if (isEditing) {
            onSave(newValue);
        }
    }

    useEffect(() => {
        if (!isEditing && onSave) {
            onSave(tempValue)
        }
    }, [isEditing])

    useEffect(() => {
        if (isEditing) {
            inputRef.current.focus();
        }
        setCurrentValue(value);
    }, [isEditing, value])

    return (
        <div className={className}>
            {isEditing ? (
                isComboBox ? (
                    <div>
                        <select
                            className={styles.editableInput}
                            onChange={handleOnChange}
                            ref={inputRef}
                            value={currentValue}
                        >
                            {dataComboBox.map((value, index) => (
                                <option key={index} value={value}>{value}</option>
                            ))}
                        </select>
                    </div>
                ) : (
                    <div>
                        <input 
                            className={styles.editableInput}
                            onChange={handleOnChange}
                            ref={inputRef}
                            value={currentValue} />
                    </div>
                )
            ):(
                <div
                    className={styles.editableValue}
                >
                    <span>{currentValue}</span>
                </div>
            )}
        </div>
    )
}