import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './CompleteRegister.module.scss';
import axios from 'axios';
import Loading from '../components/Loading';
import countries from '../jsons/countries';
import { useMsal } from '@azure/msal-react';
import { Processing } from './Processing';


export const CompleteRegister = (props) => {
    const { instance, accounts } = useMsal();
    const [nationality, setNationality] = useState('Argentina');
    const [identification, setIdentification] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();

    const GMM_URL = process.env.REACT_APP_GMM_URL;

    const handleNationalityChange = (event) => {
        setNationality(event.target.value);
       
    };

    const handleIdentificationChange = (event) => {
        setIdentification(event.target.value);
    };

    const handleLogoutRedirect = () => {
        instance.logoutRedirect();
    };

    let timeoutId;

    const completeRegistration = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const requestBody = {
                identification: parseInt(identification, 10), 
                country: nationality,
                client_id: props.idTokenClaims.oid
            };
            console.log(requestBody);

            const config = {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                }
            };

            await axios.post(
                `https://${GMM_URL}/users/complete_register`,
                requestBody,
                config
            );

            setSuccessMessage('Registro completado con éxito, volve a iniciar sesión para continuar.');
            setErrorMessage('');
        
            setTimeout(() => {
                handleLogoutRedirect();
                navigate('/home');
            }, 5000);  

        } catch (error) {
            console.error('There was an error!', error);
            setErrorMessage('Error al completar el registro');
            setSuccessMessage('');
            navigate('/error');
        } finally {
            setLoading(false);
        }
    };


    return (
        <>
            <section className={styles.section}>
                <h2>Ya casi estamos</h2>
                {loading ? (
                    <Processing/>
                ) : (
                    <form className={styles.form} onSubmit={completeRegistration}>
                        <label htmlFor="nationality" className={styles.label}>Nacionalidad</label>
                        <select 
                            name="nationality" 
                            id="nationality" 
                            className={styles.input} 
                            onChange={handleNationalityChange}
                            value={nationality}
                        >
                            {countries.countries.map((country, index) => (
                                <option key={index} value={country.name}>{country.es_name}</option>
                            ))}
                        </select>
                        <label htmlFor="identification" className={styles.label}>DNI</label>
                        <input 
                            type="number" 
                            name="identification" 
                            id="identification" 
                            placeholder="Ingresa tu DNI" 
                            className={styles.input} 
                            onChange={handleIdentificationChange}
                        />
                        {errorMessage && <p className={styles.error}>{errorMessage}</p>}
                        {successMessage && <p className={styles.success}>{successMessage}</p>}
                        <input type="submit" value="Completar registro" className={styles.submit} />
                    </form>
                )}

           
            </section>
        </>
    );
};
