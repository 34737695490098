import {GenericsError} from '../components/generics/GenericsError';
import {FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faWhatsapp } from '@fortawesome/free-brands-svg-icons' 
import {GenericButton} from '../components/generics/GenericButton';
const WHATSAPP_NUMBER = process.env.REACT_APP_WHATSAPP_NUMBER;

export const ErrorSection = () => {

  const whatsappUrl = `https://wa.me/${WHATSAPP_NUMBER}`;

  return (
    <GenericsError title={"Ocurrio un error :("}
        text="El sistema no pudo validar los datos que cargaste."
    >
        <a href="/">
            <GenericButton backgroundColor={process.env.REACT_APP_PRIMARY_COLOR}
            color={process.env.REACT_APP_BLACK_COLOR}>
                <span>Volver a intentarlo</span>
            </GenericButton>
        </a>
        <a href={whatsappUrl}>
            <GenericButton backgroundColor={process.env.REACT_APP_WHITE_COLOR}>
                <span>Solicitar ayuda <FontAwesomeIcon icon={faWhatsapp}/></span>
            </GenericButton>
        </a>
    </GenericsError>
  );
};
