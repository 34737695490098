import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import Navbar  from './Navbar';
import AuthLandingPage from '../pages/AuthLandingPage';
import DecorationBottom from './DecorationBottom'
import DeveloperWatermark from './DeveloperWatermark'

export const PageLayout = (props) => {

    return (
        <>
            <Navbar />
            <UnauthenticatedTemplate>
            <AuthLandingPage />
            </UnauthenticatedTemplate>

            {props.children}
            <AuthenticatedTemplate>
            </AuthenticatedTemplate>
            <DecorationBottom/>
            <DeveloperWatermark/>
        </>
    );
};
