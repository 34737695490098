
import styles from './AuthLandingPage.module.scss';
import { useMsal } from '@azure/msal-react';
import { loginRequest  } from '../authConfig';
import { GenericButton } from '../components/generics/GenericButton';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';


function AuthLandingPage() {
    const { instance } = useMsal();
    let activeAccount;

    if (instance) {
        activeAccount = instance.getActiveAccount();
    }

    console.log(activeAccount)


    const handleLoginRedirect = () => {
        instance.loginRedirect(loginRequest).catch((error) => console.log(error));
    };

const parkName = process.env.REACT_APP_PARK_NAME


    return (
       <section className={styles.section}>
            <div>
                <h2>Autogestión {parkName}</h2>
                <div className={styles.carousel}>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={200}
                    pagination={{
                    clickable: true,
                    }}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <img src={process.env.REACT_APP_BLOB_ROOT_FOLDER_URL + `/carousel_1.svg`} alt="Primera imagen del carousel" className={styles.sliderImage} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={process.env.REACT_APP_BLOB_ROOT_FOLDER_URL + `/carousel_2.svg`} alt="Segunda imagen del carousel" className={styles.sliderImage}/>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={process.env.REACT_APP_BLOB_ROOT_FOLDER_URL + `/carousel_3.svg`} alt="Tercera imagen del carousel" className={styles.sliderImage}/>
                    </SwiperSlide>
                </Swiper>
                </div>
                <h3>Te damos la bienvenida!</h3>
                <p>Acá podrás:</p>
                <ul>
                    <li>Reservar tu visita</li>
                    <li>Gestionar tus pedidos desde Gastronomía</li>
                    <li>Descubrir todas las actividades del parque</li>
                    <li>Acceder a beneficios exclusivos</li>
                </ul>
                <div className={styles.buttonsContainer}>
                <GenericButton
                    onClick={handleLoginRedirect}
                    color={'#FFFFFF'}
                    backgroundColor={process.env.REACT_APP_PRIMARY_COLOR}
                    
                >
                    Iniciar sesión
                </GenericButton>
                </div>
            </div>
        </section> 
        

    );
}

export default AuthLandingPage;
