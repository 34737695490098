export const DowlLogo = ({width, height}) => {
    return (
    <svg width={width} height={height} viewBox="0 0 32 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.0775906 0C0.300195 0.328826 0.539422 0.64657 0.73801 0.989251C0.91166 1.29499 1.13057 1.39751 1.48249 1.39289C3.01393 1.3735 4.54537 1.38181 6.07681 1.39289C6.16688 1.39978 6.25715 1.38253 6.33833 1.34291C6.41951 1.3033 6.48866 1.24275 6.53865 1.16752C6.7631 0.854395 7.00048 0.553279 7.23694 0.249392C7.27389 0.201361 7.31176 0.131165 7.37734 0.143173C7.46971 0.161647 7.44107 0.25309 7.44754 0.316823C7.56228 1.28712 7.60612 2.26449 7.5787 3.24116C7.51866 4.67007 7.32192 6.0722 6.73816 7.39766C5.9863 9.10553 4.78275 10.3377 3.03517 11.0277C2.139 11.3973 1.2145 11.6942 0.270634 11.9153C0.0212437 11.9698 0 11.9569 0 11.7057C0 7.87366 0 4.04136 0 0.20875C0 0.147788 0.00923804 0.0877494 0.0129327 0.0240163L0.0775906 0ZM4.86773 5.9512H5.14483C5.85421 5.9512 6.12392 5.75538 6.3456 5.08203C6.72523 3.93114 6.2985 3.00377 5.16146 2.58812C4.22856 2.24544 3.27163 2.28885 2.32118 2.52346C1.95228 2.60846 1.60803 2.77759 1.3153 3.01763C0.476612 3.72239 0.734318 5.17347 1.19061 5.7249C1.25251 5.79924 1.33071 5.85832 1.41913 5.89757C1.50755 5.93681 1.60382 5.95516 1.70048 5.9512C2.02376 5.9512 2.34704 5.92718 2.6694 5.95859C3.05457 5.99646 3.2938 5.83944 3.46745 5.51153C3.59215 5.27508 3.63833 5.27138 3.76487 5.50138C3.95977 5.85514 4.23687 6.01124 4.63682 5.95397C4.71362 5.9475 4.7908 5.94657 4.86773 5.9512Z" fill="#00517D"/>
    <path d="M10.0068 5.95501C10.0068 4.80781 10.0068 3.66123 10.0068 2.51527C10.0068 2.21046 10.0465 2.17166 10.356 2.17074C11.1946 2.17074 12.0343 2.1458 12.8711 2.17628C15.305 2.26865 16.8152 4.31642 16.5057 6.48981C16.321 7.78295 15.6744 8.7528 14.5023 9.35319C13.9481 9.63583 13.3477 9.73097 12.7372 9.74482C11.953 9.76145 11.1669 9.75036 10.3827 9.74482C10.0576 9.74482 10.0077 9.69125 10.0068 9.36889C10.0062 8.23155 10.0062 7.09359 10.0068 5.95501ZM11.0912 5.93746C11.0912 6.75953 11.0958 7.58251 11.0912 8.4055C11.0912 8.60132 11.1475 8.6826 11.3535 8.67706C11.8154 8.66505 12.2772 8.68445 12.739 8.66967C13.2683 8.65305 13.7772 8.54129 14.2243 8.2374C14.9632 7.73492 15.3382 7.02 15.402 6.14344C15.5026 4.77271 14.7277 3.50452 13.1852 3.28006C12.5811 3.19324 11.9715 3.26529 11.3655 3.2385C11.1568 3.22927 11.0838 3.29669 11.0884 3.5156C11.0977 4.32197 11.0912 5.12925 11.0912 5.93746Z" fill="#00517D"/>
    <path d="M24.2617 7.88637C24.5406 7.22933 24.8177 6.57261 25.093 5.91619C25.1743 5.72499 25.2574 5.53471 25.3322 5.34074C25.3932 5.18187 25.4902 5.08396 25.6721 5.08396C25.8541 5.08396 25.9437 5.18926 26.0093 5.34536C26.352 6.16096 26.6993 6.97379 27.0447 7.78754C27.0586 7.82079 27.0789 7.8522 27.1205 7.93163C27.2405 7.6222 27.3477 7.35619 27.4465 7.08647C27.6636 6.4944 27.8825 5.90325 28.0931 5.30749C28.112 5.22021 28.1631 5.14326 28.2362 5.09192C28.3092 5.04058 28.399 5.01861 28.4875 5.03039C28.6565 5.03778 28.8265 5.03039 28.9955 5.03039C29.2504 5.03778 29.3012 5.1089 29.2079 5.34628C28.9863 5.91157 28.7581 6.475 28.5318 7.03844L27.5878 9.3938C27.561 9.47389 27.5248 9.55049 27.4798 9.62195C27.3375 9.81592 27.0983 9.76604 26.9708 9.50926C26.7482 9.06128 26.5367 8.60868 26.3243 8.15793C26.1183 7.73212 25.9095 7.30723 25.6777 6.83431C25.4532 7.29615 25.2519 7.69517 25.0561 8.10066C24.8319 8.56496 24.6105 9.03049 24.3919 9.49725C24.3291 9.63118 24.2488 9.7374 24.0853 9.73556C23.9218 9.73371 23.8488 9.61179 23.7952 9.47878C23.4443 8.60068 23.0936 7.72196 22.7432 6.84262C22.5492 6.3577 22.3515 5.8737 22.1585 5.38692C22.0449 5.10058 22.0929 5.02762 22.4079 5.03039C23.2771 5.03685 23.1071 4.92047 23.4175 5.74254C23.6853 6.45284 23.944 7.16776 24.2072 7.88083L24.2617 7.88637Z" fill="#00517D"/>
    <path d="M21.8907 7.32374C21.8918 7.80479 21.7501 8.27534 21.4834 8.67573C21.2168 9.07613 20.8373 9.38835 20.393 9.57281C19.9487 9.75728 19.4597 9.80569 18.9879 9.71191C18.5161 9.61813 18.0827 9.38638 17.7428 9.04603C17.4028 8.70568 17.1715 8.27206 17.0783 7.80014C16.9851 7.32821 17.034 6.83922 17.219 6.39516C17.404 5.9511 17.7166 5.57195 18.1173 5.30578C18.518 5.03961 18.9887 4.89839 19.4698 4.90004C20.1109 4.90464 20.7244 5.16156 21.1775 5.61518C21.6306 6.06879 21.8869 6.68261 21.8907 7.32374ZM19.4615 8.71848C20.2078 8.71848 20.847 8.07192 20.847 7.30619C20.847 6.54047 20.2004 5.87266 19.4495 5.88005C18.6985 5.88744 18.0575 6.55617 18.064 7.32651C18.0778 8.06268 18.7318 8.71663 19.4615 8.71848Z" fill="#00517D"/>
    <path d="M31.0294 5.94672C31.0294 7.0699 31.0294 8.19308 31.0294 9.31626C31.0294 9.70882 30.9897 9.74669 30.5897 9.74761C30.5352 9.74761 30.4817 9.74761 30.4281 9.74761C30.0836 9.73376 29.958 9.60907 29.957 9.26361C29.957 8.26297 29.957 7.26295 29.957 6.26354C29.957 5.01659 29.957 3.77025 29.957 2.52452C29.957 2.21417 29.9949 2.1763 30.2988 2.17261C30.4373 2.17261 30.5759 2.17261 30.7144 2.17261C30.9712 2.17815 31.0276 2.22988 31.0285 2.48666C31.0285 3.17202 31.0285 3.85646 31.0285 4.54089L31.0294 5.94672Z" fill="#00517D"/>
    <path d="M3.06752 4.27388C3.06729 4.37962 3.04604 4.48426 3.00502 4.58172C2.964 4.67919 2.90402 4.76753 2.82856 4.84161C2.75311 4.91569 2.66368 4.97403 2.56548 5.01326C2.46728 5.05248 2.36226 5.07179 2.25653 5.07008C2.03949 5.06267 1.83376 4.97146 1.68255 4.81559C1.53133 4.65972 1.44639 4.45133 1.44556 4.23416C1.45572 3.81666 1.86767 3.41671 2.27686 3.42964C2.3843 3.43286 2.49005 3.45729 2.58802 3.50152C2.68599 3.54574 2.77425 3.6089 2.84773 3.68736C2.92121 3.76582 2.97845 3.85802 3.01617 3.95868C3.05389 4.05933 3.07134 4.16645 3.06752 4.27388Z" fill="#00517D"/>
    <path d="M5.66755 4.28222C5.66755 4.69325 5.27869 5.0701 4.8575 5.0701C4.64047 5.06681 4.43318 4.97951 4.27918 4.82655C4.12519 4.67359 4.03649 4.46688 4.03174 4.24988C4.03174 3.83516 4.43907 3.4269 4.84733 3.42875C5.06874 3.4348 5.27887 3.5277 5.43234 3.68739C5.58582 3.84708 5.6703 4.06075 5.66755 4.28222Z" fill="#00517D"/>
    </svg>
    )
}
