import style from "./Profile.module.scss";
import { CenteredLayout } from "../components/generics/CenteredLayout";
import { GenericButton } from "../components/generics/GenericButton";
import { ProfileImage } from "../components/ProfileImage";
import { PencilLogo } from "../components/icons/PencilLogo";
import { SignOutLogo } from "../components/icons/SignOutLogo";
import { useGetProfileData } from "../hooks/useGetProfileData";
import { useUpdateUserData } from "../hooks/useUpdateUser";
import { useUpdateUserPicture } from "../hooks/useUpdateUserPicture";
import { EditableField } from "../components/EditableField";
import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";

const buttonLayout = {
    margin: '0',
    marginLeft: '10px'
};

const labelsFromFields = {
    name: 'Nombre',
    surname: 'Apellido',
    email: 'Email',
    country: 'Nacionalidad',
    identification: 'DNI'
};

export const Profile = () => {
    const GMM_URL = process.env.REACT_APP_GMM_URL;
    const { instance } = useMsal();
    const { idTokenClaims: { extension_isaUUID } } = instance.getActiveAccount(instance);
    const { name, surname, full_name, email, country, identification, profile_picture } = useGetProfileData(instance);
    const [isEditing, setIsEditing] = useState(false);
    const [modifiedUser, setModifiedUser] = useState({});
    const [selectedImage, setSelectedImage] = useState(null);
    const { updateUserData } = useUpdateUserData(GMM_URL, extension_isaUUID);
    const { updateUserPicture } = useUpdateUserPicture(GMM_URL, extension_isaUUID);

    useEffect(() => {
        if (!name || !surname || !full_name || !country || !identification) return;

        setModifiedUser({
            name,
            surname,
            email,
            country,
            identification
        });

    }, [name, surname, full_name, email, country, identification]);

    const handleFieldChange = (field, value) => {
        setModifiedUser(prevUser => ({
            ...prevUser,
            [field]: value,
        }));
    };
    const handleImageChange = (event) => {
        const imageFile = event.target.files[0];
        setSelectedImage(imageFile);
    };

    const handleSaveChanges = async () => {
        try {
            await updateUserData(modifiedUser);
            if (selectedImage) {
                await updateUserPicture(selectedImage);
            }
            setIsEditing(false);
        } catch (error) {
            console.error("Error saving changes:", error);
        }
    };

    return (
        <>
            <CenteredLayout>
                <ProfileImage profileImageUrl={profile_picture} selectedImage={selectedImage} />
                {isEditing ? (
                    <input type="file" accept="image/*" onChange={handleImageChange} />
                ) : null}
                <h3>Mi perfil</h3>
            </CenteredLayout>
            <section className={style.profileData}>
                <div className={style.profileDataGrid}>
                    {Object.entries(modifiedUser).map(([field, value]) => (
                        <div className={style.column} key={field}>
                            <h6>{labelsFromFields[field]}</h6>
                            {field !== 'identification' && field !== 'country' &&
                                <EditableField
                                    value={value}
                                    isEditing={isEditing}
                                    className={
                                        (field === 'email' && style.emailField)
                                    }
                                    onSave={(newValue) => handleFieldChange(field, newValue)}
                                />}
                            {(field === 'identification' || field === 'country') && (
                                <p>{value}</p>
                            )}
                        </div>
                    ))}
                </div>
            </section>
            <CenteredLayout style={buttonLayout}>
                {isEditing ? (
                    <>
                        <GenericButton onClick={() => setIsEditing(false)}>
                            <span className={style.editingButtonLayout}>
                                <p className={style.buttonText}>Cancelar</p>
                            </span>
                        </GenericButton>
                        <GenericButton onClick={handleSaveChanges}
                            backgroundColor={process.env.REACT_APP_PRIMARY_COLOR}
                            color={process.env.REACT_APP_WHITE_COLOR}>
                            <span className={style.editingButtonLayout}>
                                <p className={style.buttonText}>Guardar cambios</p>
                            </span>
                        </GenericButton>
                    </>
                ) : (
                    <>
                        <GenericButton onClick={() => setIsEditing(true)}>
                            <PencilLogo width={'2rem'} className={style.pencilLogo} />
                            Editar perfil
                        </GenericButton>
                        <GenericButton onClick={() => instance.logoutRedirect()}>
                            <SignOutLogo width={'2rem'} />
                            Cerrar sesión
                        </GenericButton>
                    </>
                )}
            </CenteredLayout>
        </>
    );
};
