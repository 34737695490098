import { useActiveItems } from '../hooks/useActiveItems';
import style from './RestaurantPoint.module.scss';
import minus from '../assets/minus.svg';
import plus from '../assets/plus.svg';
import { GenericButton } from '../components/generics/GenericButton';
import { useEffect, useMemo, useState } from 'react';
import { Checkbox } from '../components/Checkbox'
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';

const Minus = ()=> {
    return ( <img src={minus} alt='Minus symbol'/> )
}
const Plus = ()=> {
    return ( <img src={plus} alt="Plus symbol"/>)
}

const PRIMARY_COLOR = process.env.REACT_APP_PRIMARY_COLOR;
const SECONDARY_COLOR = process.env.REACT_APP_SECONDARY_COLOR;
const WHITE_COLOR = process.env.REACT_APP_WHITE_COLOR;
const DISABLED_COLOR = process.env.REACT_APP_DISABLED_COLOR;

const Counter = ({number, minusHandler, plusHandler}) => {
    return (
    <span className={style.counter}>
        <div className={style.button_left}>
            <button onClick={()=> minusHandler()}>{<Minus/>}</button>
        </div>
        <div className={style.counter_number}>
            <p>{number}</p>
        </div>
        <div className={style.button_right}>
            <button onClick={() => plusHandler()}>{<Plus/>}</button>
        </div>
    </span>
    )
}

export const RestaurantPoint = ({ point }) => {
    const { instance } = useMsal();
    const { idTokenClaims : { extension_isaUUID } } = instance.getActiveAccount();
    const navigate = useNavigate();
    const items = useActiveItems();
    const [itemsAtRestaurant, setItemsAtRestaurant] = useState([]);
    const [availableItems, setAvailableItems] = useState({}); 
    const [selectedVariants, setSelectedVariants] = useState({});
    const [userConfirmed, setUserConfirmed] = useState(false);
    const [checkboxChecked, setCheckboxChecked] = useState(false);
    const [userPostedAnOrder, setUserPostedAnOrder] = useState(false);
    const [orderCode, setOrderCode] = useState('');
    
    const numberOfSelectedItems = useMemo(()=> {
        return Object.values(selectedVariants).reduce((acc, val) => acc + val.qty, 0);
    }, [selectedVariants]);


    useEffect(()=>{
        if(items < 0){return}
        const avbItems = items.reduce((acc, item)=> {
            if(item.info.external_food_point.includes(point.str_id)){
                acc[item.info.name] = item.pending_qty;
            }
            return acc;
        }, {})
        const selectedItems = items.reduce((acc, item)=>{
            item.info.variants.forEach((variant)=>{
                acc[variant.uuid] = {
                    name: variant.public_name,
                    qty: 0,
                    isa_uuid: item.orderitem_id
                };
            })
            return acc;
        }, {})

        const itemsAtRestaurant = items.filter((item)=> item.info.external_food_point.includes(point.str_id));
        const activeItemsAtRestaurant = itemsAtRestaurant.map(item => {
            return {
                ...item,
                info: {
                    ...item.info,
                    variants: item.info.variants.filter(variant => variant.active)
                }
            };
        });
        console.log(activeItemsAtRestaurant);
        setItemsAtRestaurant(activeItemsAtRestaurant);
        setAvailableItems(avbItems);
        setSelectedVariants(selectedItems);
    }, [items])
    useEffect(()=> {
        if(numberOfSelectedItems > 0){
            navigate('/reserved', {state: {code: orderCode, point: point.name}})
        }
    }, [userPostedAnOrder])
    const handleMinus = (uuid) => {
        const minLimit = 0;

        if(selectedVariants[uuid].qty > minLimit){
            setSelectedVariants(
                prev => (
                    {...prev, [uuid]: { ...prev[uuid], qty: selectedVariants[uuid].qty - 1 }}
                ))
        }
    }
    const handlePlus = (itemName, uuid) => {
        const maxStaticLimit = availableItems[itemName];
        const currentValue = selectedVariants[uuid].qty;
        const totalForItem = Object.entries(selectedVariants)
        .filter(([_, variant]) => variant.isa_uuid === selectedVariants[uuid].isa_uuid)
        .reduce((total, [_, variant]) => total + variant.qty, 0);
        if( currentValue < maxStaticLimit && totalForItem < maxStaticLimit){
            setSelectedVariants(
                prev => (
                    {...prev, [uuid]: { ...prev[uuid], qty: selectedVariants[uuid].qty + 1 }}
                    ))
        }

    }
    const handleCheckbox = (e) => {
        setCheckboxChecked(e.target.checked);
    }
    const handleConfirm = () => {
        if(numberOfSelectedItems > 0){
            setUserConfirmed(true);
        }
    }
    const postOrder = async () => {
        try{
            const post = async () => {
                const items = []
                for (const [uuid, variant] of Object.entries(selectedVariants)) {
                    if(variant.qty > 0){
                        items.push({
                            isa_orderitem_id : variant.isa_uuid,
                            isa_product_variant_uuid: uuid,
                            qty: variant.qty
                        })
                    }
                }
                const payload = {
                    client_uuid: extension_isaUUID,
                    food_point_str_id: point.str_id,
                    items: items
                }
                const response = await axios.post(`https://${process.env.REACT_APP_GMM_FOODING_URL}/orders/`, payload);
                console.debug(payload)
                const { data } = response;
                console.debug('Data: ');
                console.debug(data);
                return data
            }
            const d = await post();
            setOrderCode(d.data.verification_code);
        }
        catch(err){
            console.log(err);
        }
        setUserPostedAnOrder(true);
    }

    return (
        <main>
            {!userConfirmed && (
                <section 
                    className={style.name}>
                    <h2>{point.name}</h2>
                </section>
            )}
            <section
                className={style.main_section}>
                {!userConfirmed && (

                <>
                    <h5>Elegi tu pedido</h5>
                    {itemsAtRestaurant.length > 0 && itemsAtRestaurant.map((item) => (
                        <>
                        <h6>x{item.pending_qty} {item.info.name}</h6>
                        <span className={style.variants}>
                            {item.info.variants.map((variant)=>(
                                <span className={style.variant_row} key={variant.pub}>
                                    <p>{variant.public_name}</p>
                                    <Counter 
                                        number={selectedVariants[variant.uuid].qty}
                                        minusHandler={()=> handleMinus(variant.uuid)}
                                        plusHandler={()=> handlePlus(item.info.name, variant.uuid)}
                                        />
                                </span>
                            ))}
                        </span>
                        </>
                    ))}
                </>

                )}
                {userConfirmed && (
                    <section className={style.confirmation}>
                        <h2 className={style.order_resume_title}>Tu pedido en {point.name}</h2>
                        {Object.entries(selectedVariants).map(([uuid, variant]) => (
                            variant.qty > 0 && (
                                <p key={uuid}>
                                    {variant.qty} x {variant.name}
                                </p>
                            )
                        ))}
                    </section>
                )}
            </section>
            <section className={`${style.canjear_button} ${userConfirmed ? style.fixed_bottom : ''}`}>
                {userConfirmed && (
                    <section className={style.warning}>
                        <p>Antes de confirmar tu pedido, asegurate de estar en el local {point.name} para retirarlo.</p>
                        <Checkbox textLabel={'Estoy en el local'}
                            onChange={e => handleCheckbox(e)}/>
                    </section>
                )}
                <GenericButton 
                backgroundColor={
                    userConfirmed
                        ? (checkboxChecked ? PRIMARY_COLOR : DISABLED_COLOR)
                        : (numberOfSelectedItems > 0 ? PRIMARY_COLOR : DISABLED_COLOR)
                }
                color={WHITE_COLOR}
                onClick={
                    userConfirmed
                        ? (checkboxChecked 
                            ? (userPostedAnOrder 
                                ? null
                                : postOrder)
                            : null)
                        : (numberOfSelectedItems > 0 ? handleConfirm : null)
                }
                >
                    <span>
                        Canjear
                    </span>
                </GenericButton>
            </section>
        </main>
)
}