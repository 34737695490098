import React from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import { Navigate, Outlet } from 'react-router-dom';


export const PrivateRoute = () => {
  const isAuthenticated = useIsAuthenticated();

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }


  return <Outlet />;
};