import React, { useEffect, useState} from 'react';
import axios from 'axios';
import { useAtom } from 'jotai';
import { isVisitActiveAtom } from '../atoms/globalAtoms';
import styles from './DataDisplay.module.scss';
import QRCode from 'react-qr-code';
import { GenericButton } from './generics/GenericButton';
import { useGetProfileData } from '../hooks/useGetProfileData';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom'; 
import { ProfileImage } from "../components/ProfileImage";


export const DataDisplay = (props) => {
    const [isVisitActive, setIsVisitActive] = useAtom(isVisitActiveAtom);
    const [message, setMessage] = useState('');
    const { instance } = useMsal();
    const navigate = useNavigate();
    const client_data = useGetProfileData(instance)
    const GMM_URL = process.env.REACT_APP_GMM_URL;
    const expectedParkId = process.env.REACT_APP_PARK;
    const expectedParkName = process.env.REACT_APP_PARK_NAME;
    const parkUrls = {
    mm: process.env.REACT_APP_MM_URL,
    tm: process.env.REACT_APP_TM_URL,
    po: process.env.REACT_APP_PO_URL
    };

    //FIXME USE THE HOOK FROM THE FILE
    useEffect(() => {
        const getActiveVisit = async () => {
            try {
                const client_id = props.idTokenClaims.extension_isaUUID;
                const response = await axios.post(`https://${GMM_URL}/attendants/get_active_visit?client_id=${client_id}`);
                const visit = response.data.data.visit;

                console.log(response, visit);

                setIsVisitActive(visit !== null);
   
                if (visit.park_id !== expectedParkId) {
                    const redirectUrl = parkUrls[visit.park_id];
                    if (redirectUrl) {
                    
                    setMessage("Tienes una visita activa, seras redireccionado al parque de tu visita...")
                     setTimeout(() => {
                         window.location.href = redirectUrl;
                     }, 4000);  

                    }
                }
            } catch (err) {
                console.error(err);
            }
        };


        if (props.idTokenClaims.extension_isaUUID) {
            getActiveVisit();
        }
    }, [props.idTokenClaims, GMM_URL, setIsVisitActive]);

    const handleRedirect = (url) => {
        navigate(url);
    };

    return (
        <>
                <section className={styles.section}>
                    <ProfileImage profileImageUrl={client_data.profile_picture} />
                    <p>{client_data.full_name}</p>
                    <p>{client_data.identification}</p>
                    {props.idTokenClaims.extension_isaUUID ? (
                        <QRCode className={styles.qr} value={props.idTokenClaims.extension_isaUUID}/> 
                    ) : (
                        <p>Generando QR...</p>
                    )}

                     {message && <p className={styles.infoMessage}>{message}</p>}
         
                    <GenericButton
                        color={'#1E1E1E'}
                        backgroundColor={'#FFFFFF'}
                        onClick={() => window.location.href = process.env.REACT_APP_TICKETS_URL}
                    >Comprar entradas</GenericButton>
                 

                    {isVisitActive && (
                     
                        <GenericButton
                            color={'#FFFFFF'}
                            backgroundColor={process.env.REACT_APP_SECONDARY_COLOR}
                            onClick={() => handleRedirect('/visit')}
                        >Mi visita en {expectedParkName}</GenericButton>
                    
                    )}
                </section>
        </>
    );
};
