import styles from './SuccessSection.module.scss';


export const SuccessSection = () => {
  const imageUrl = process.env.REACT_APP_BLOB_ROOT_FOLDER_URL + `/success.svg`;

  return (
    <section className={styles.section}>
      <p>Reserva exitosa!</p>
      <img src={imageUrl} alt="Reserva Exitosa" className='image'/>
      <button><a href="/">Volver al Inicio</a></button>
    </section>
  );
};
