import { useEffect, useState } from "react";
import axios from 'axios';

export function useGetProfileData(instance) {
    const { idTokenClaims : { extension_isaUUID } } = instance.getActiveAccount();
    const GMM_URL = process.env.REACT_APP_GMM_URL;
    const [user, setUser] = useState({});

    useEffect(()=> {
            console.log('Calling API')
            const getResponse = async () => {
                try{
                    const {data : {data}} = await axios.get(`https://${GMM_URL}/users/${extension_isaUUID}`);
                    if(data){
                        setUser(data)
                        sessionStorage.setItem(extension_isaUUID, JSON.stringify(data));
                    }
                }catch(err){
                    console.log(err)
                    throw new Error(err)
                }
            }
            getResponse();
    }, [GMM_URL, instance])
    return user;
}