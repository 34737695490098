// authConfig.js


import { LogLevel } from '@azure/msal-browser';

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const TENANT_NAME = process.env.REACT_APP_TENANT_NAME;
const SIGNUPSIGNIN_USER_FLOW = process.env.REACT_APP_SIGNUPSIGNIN_USER_FLOW;
const AUTHORITY_DOMAIN = `${TENANT_NAME}.b2clogin.com`;
export const b2cPolicies = {
    names: {
        signUpSignIn: SIGNUPSIGNIN_USER_FLOW,
        forgotPassword: 'B2C_1_reset_v3',
        editProfile: 'B2C_1_edit_profile_v2',
    },
    authorities: {
        signUpSignIn: {
            authority: `https://${AUTHORITY_DOMAIN}/${TENANT_NAME}.onmicrosoft.com/${SIGNUPSIGNIN_USER_FLOW}`,
        },
        forgotPassword: {
            authority: 'https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/B2C_1_reset_v3',
        },
        editProfile: {
            authority: 'https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/b2c_1_edit_profile_v2',
        },
    },
    authorityDomain: AUTHORITY_DOMAIN,
};


export const msalConfig = {
    auth: {
        clientId: CLIENT_ID,
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        redirectUri: '/', // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    },
};


/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: [],
};



export const silentRequest = {
    scopes: ["openid", "profile"],
    loginHint: "example@domain.net"
};

/* const TENANT_NAME = 'dowltestb2c'; 
const SIGNUPSIGNIN_USER_FLOW = 'B2C_1_signupsignin1'; 

export const msalConfig = {
    auth: {
        clientId: '39f74d6c-bb44-4fe1-b020-fa1cb2a24992',
        authority: `https://${TENANT_NAME}.b2clogin.com/${TENANT_NAME}.onmicrosoft.com/${SIGNUPSIGNIN_USER_FLOW}`,
        redirectUri: 'http://localhost:3000',
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    },
}; */
