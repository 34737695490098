import { useEffect, useState } from "react";

import { GenericButton } from "./generics/GenericButton";
import { OrderDetails } from "./OrderDetails";
import styles from './OrderCard.module.scss';
import whatsappLogo from '../assets/whatsapp-logo.svg';
import ARROW from '../assets/down-arrow.svg';

const WHATSAPP_NUMBER = process.env.REACT_APP_WHATSAPP_NUMBER;

export const OrderCard = ({ order }) => {
    const client = order.client;
    const encodedText = encodeURIComponent(`Hola, mi nombre es ${client.name} ${client.surname} y quiero realizar una consulta sobre la reserva ${order.str_id}: **Inserta tu consulta aquí** `)
    const whatsappURL = `https://wa.me/${WHATSAPP_NUMBER}?text=${encodedText}`
    
    const [extended, setExtended] = useState(false);

    const showDetails = () => {
        setExtended(!extended);
    }
    useEffect(()=> console.log(order), [extended])

    return (
        <div className={styles.container}>
            <OrderDetails detailed={extended} order={order}/>
            <div className={styles.buttons_grid}>
                <a href={whatsappURL}>
                    <GenericButton>
                        <span className={styles.whatsapp_button}>
                            Solicitar ayuda
                            <img src={whatsappLogo} alt="Logo whatsapp" className={styles.whatsapp_logo}/>
                        </span>
                    </GenericButton>
                </a>
                <GenericButton onClick={showDetails}>
                    {extended ? 
                        <span>Ocultar detalles
                            <img src={ARROW} alt="Arrow details" className={styles.up_arrow}/>
                        </span>
                    :
                        <span>Ver detalles
                            <img src={ARROW} alt="Arrow details" className={styles.down_arrow}/>
                        </span>
                    }
                </GenericButton>
            </div>
        </div>
    )
}