import React from 'react';

function DecorationBottom() {
    const decorationImageUrl = process.env.REACT_APP_BLOB_ROOT_FOLDER_URL + `/decorationBackground.svg`;

    const decorationStyle = {
        backgroundImage: `url(${decorationImageUrl})`,
        position: 'fixed',
        bottom: 0,
        width: '100%',
        height: '100px', 
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
         zIndex: 0,
    };

    return (
        <div style={decorationStyle}>
            {/* You can add more content here if needed */}
        </div>
    );
}

export default DecorationBottom;
