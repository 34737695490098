import styles from './GenericSuccess.module.scss';
import { CenteredLayout } from './CenteredLayout';

export const GenericsSuccess = ({ text, children }) => {
    const reservationImage = process.env.REACT_APP_BLOB_ROOT_FOLDER_URL + `/success.svg`;

    return (
        <CenteredLayout>
            <h2 className={styles.h2}>{text}</h2>
            <img src={reservationImage} alt="Notification image" className='image' />
            {children}
        </CenteredLayout>
    );
}