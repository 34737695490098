import axios from 'axios';
import { useState } from 'react';

export const useUpdateUserData = (GMM_URL, extension_isaUUID) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const updateUserData = async (userData) => {
        setIsLoading(true);
        setError(null);
        try {
            await axios.put(`https://${GMM_URL}/users/update/${extension_isaUUID}`, userData);
            setIsLoading(false);
        } catch (error) {
            setError(error);
            setIsLoading(false);
            throw error;
        }
    };

    return { updateUserData, isLoading, error };
};
