import styles from './Orders.module.scss';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { OrderCard } from '../components/OrderCard';
import Loading from '../components/Loading';
const GMM_URL = process.env.REACT_APP_GMM_URL;

export const Orders = () => {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const [ordersArray, setOrdersArray] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const client_uuid = activeAccount.idTokenClaims.extension_isaUUID;
                const response = await axios.get(`https://${GMM_URL}/orders/`, {
                    params: {client_uuid: client_uuid},
                });
                const {data : {data}} = response;
                if(data && data.hasOwnProperty('orders')){
                    setOrdersArray(data.orders)
                }else{ 
                    throw new Error('No se encontraron reservas')
                }
                setLoading(false);
            } catch (e) {
                console.log(e)
                navigate('/error')
            }
        }
        fetchOrders();
    }, [activeAccount.idTokenClaims.extension_isaUUID])


    return (
        <div>
            {loading &&
                <section className={styles.center_message}>
                    <Loading/>
                </section>
            }
            {!loading && ordersArray.length > 0 &&
             <section className={styles.section}>
                    <h2 className={styles.title}>Mis compras</h2>
                    {ordersArray.map(order => (
                        <OrderCard order={order} key={order.str_id} />
                    ))}
                    </section>
            }
        </div>
    )
}