import axios from 'axios';
import { useState } from 'react';

export const useUpdateUserPicture = (GMM_URL, extension_isaUUID) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const updateUserPicture = async (selectedImage) => {
        setIsLoading(true);
        setError(null);
        try {
            const formData = new FormData();
            formData.append('updated_profile_picture', selectedImage, selectedImage.name);
            await axios.put(`https://${GMM_URL}/users/update_profile_picture/${extension_isaUUID}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'accept': 'application/json'
                }
            });
            setIsLoading(false);
        } catch (error) {
            setError(error);
            setIsLoading(false);
            throw error;
        }
    };

    return { updateUserPicture, isLoading, error };
};
