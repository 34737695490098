import { AuthenticatedTemplate } from '@azure/msal-react';
import { useMsal } from '@azure/msal-react';
import { Container } from 'react-bootstrap';
import { DataDisplay } from '../components/DataDisplay';
import { CompleteRegister } from '../components/CompleteRegister';

export const Home = () => {
    const { instance } = useMsal();
    const activeAccount = instance ? instance.getActiveAccount() : null;
    
    const hasIsaUUID = activeAccount && activeAccount.idTokenClaims && activeAccount.idTokenClaims.extension_isaUUID;

    return (
        <>
            <AuthenticatedTemplate>
                {hasIsaUUID ? (
                    <Container style={{ zIndex: 10, position: 'relative' }}>
                        <DataDisplay idTokenClaims={activeAccount.idTokenClaims} />
                    </Container>
                ) : (
                    <CompleteRegister idTokenClaims={activeAccount ? activeAccount.idTokenClaims : {}} />
                )}
            </AuthenticatedTemplate>
        </>
    );
};
