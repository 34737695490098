import { useEffect, useState } from "react";
import { useMsal } from '@azure/msal-react';
import axios from "axios";
import { useNavigate } from "react-router";
const GMM_URL = process.env.REACT_APP_GMM_URL;

export const useActiveVisit = (activitiesOnPark) => {
    const navigate = useNavigate();
    const [activeVisit, setActiveVisit] = useState(null);
    const { instance } = useMsal();

    useEffect(() => {
        const activeAccount = instance.getActiveAccount();

        const getActiveVisit = async () => {
            try{
                const client_id = activeAccount.idTokenClaims.extension_isaUUID;
                const {data: {data: {visit}}} = await axios.post(`https://${GMM_URL}/attendants/get_active_visit?client_id=${client_id}`)
                setActiveVisit(visit)
            } catch (err){
                console.log(err);
                navigate("/error");
            }
        }
        if(Object.keys(activitiesOnPark).length > 0){
            getActiveVisit()
        }
    }, [activitiesOnPark, instance, navigate]);

    return activeVisit;
}