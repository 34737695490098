import { useEffect, useState, memo, useMemo } from 'react';
import styles from './OrderDetails.module.scss';
import PENDING_ORDER from '../assets/pending-order.svg';
import PAID_ORDER from '../assets/approved-order.svg';

const calculateItems = (items, countMethod) => {
    return items.reduce((prev, curr) => {
        return prev + countMethod(curr) * curr.qty
    }, 0)
}

const statusSpan = {
    'paid': <span className={`${styles.order_status} ${styles.paid}`}> <img src={PAID_ORDER} alt="" />Aprobada</span>,
    'pending': <span className={`${styles.order_status} ${styles.pending}`}> <img src={PENDING_ORDER} alt="" />Pendiente</span>
}
const DetailedOrder = memo(({isDetailed, order}) => {
    const ticketCount = useMemo(() => calculateItems(order.items, item => item.is_ticket), [order.items])
    const additionalCount = useMemo(() => calculateItems(order.items, item => !item.is_ticket), [order.items])
    return (
        <section className={styles.data_container}>
        {statusSpan[order.status]}
        {isDetailed &&             
            <section className={styles.extended_order}>
                {order.items.map(item => {
                    return (
                        <p key={item.id} className={styles.order_data}>{item.qty} {item.name}</p>
                        )
                    })}
            </section>
        }

        {!isDetailed &&
            <section className={styles.data_container}>
                <p className={styles.order_data}>Cantidad de entradas: {ticketCount}</p>
                <p className={styles.order_data}>Adicionales: {additionalCount}</p>
            </section>
        }

        </section>
    )
})
export const OrderDetails = ({ detailed, order}) => {
    const [date, setDate] = useState('');

    useEffect(()=> {
        const tempDate = new Date(order.created_at)
        setDate(`${tempDate.getDate()}/${tempDate.getMonth()+1}/${tempDate.getFullYear()}`)
    }, [order])


    return (<>
            <span className={styles.order_header}>
                <p className={styles.order_id}>{order.str_id}</p>
                <span className={styles.date_grid}>
                    <p className={styles.order_date}>Creación:</p>
                    <p className={styles.order_date}>{date}</p>
                </span>
            </span>
            <DetailedOrder isDetailed={detailed} order={order}/>

    </>)
}

