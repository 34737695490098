import { useEffect, useState } from "react";
import axios from "axios";
const GMM_URL = process.env.REACT_APP_GMM_URL;
export const useActivities = (park) => {

    const [activitiesOnPark, setActivitiesOnPark] = useState([]);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const fetchActivities = async () => {
            try{
                const {data:{data:{activities}}} = await axios.get(`https://${GMM_URL}/activities/park/${park}`);
                setActivitiesOnPark(activities);
                setCategories(Object.keys(activities).sort());
            } catch (err){
                console.log(err);
            }
        }
        fetchActivities();
    }, [park]);

    return { activitiesOnPark, categories }
}