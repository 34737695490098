import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { MsalProvider, useMsal } from '@azure/msal-react';
import { EventType } from '@azure/msal-browser';

import { PageLayout } from './components/PageLayout';
import { Home } from './pages/Home';
import { b2cPolicies } from './authConfig';
import { compareIssuingPolicy } from './utils/claimUtils';
import { useRestaurantPoints } from './hooks/useSellingPoints';
import { RestaurantPoint } from './pages/RestaurantPoint';	
import './styles/App.css';
import './App.css'
import {PrivateRoute} from './components/PrivateRoute';
import MyVisit from './pages/MyVisit';
import { Activities } from './pages/Activities';
import { SuccessSection } from './pages/SuccessSection';
import { Orders } from './pages/Orders';
import { ErrorSection } from './pages/ErrorSection';
import { GenericsError } from './components/generics/GenericsError';
import { Gastronomy } from './pages/Gastronomy';
import { Profile } from './pages/Profile';
import { ReservedOrder } from './pages/ReservedOrder';
import  FindUs  from './pages/FindUs';
import  InfoLinks  from './pages/InfoLinks';

const PARK = process.env.REACT_APP_PARK;

const Pages = () => {
  const { instance } = useMsal();
  const restaurants = useRestaurantPoints(PARK);
  useEffect(() => {
    const callbackId = instance.addEventCallback((event) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
        event.payload.account
      ) {

        if (event.payload.idTokenClaims['tfp'] === b2cPolicies.names.editProfile) {
          const originalSignInAccount = instance
            .getAllAccounts()
            .find(
              (account) =>
                account.idTokenClaims.oid === event.payload.idTokenClaims.oid &&
                account.idTokenClaims.sub === event.payload.idTokenClaims.sub &&
                account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn
            );

          let signUpSignInFlowRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            account: originalSignInAccount,
          };

          // Silently login again with the signUpSignIn policy
          instance.ssoSilent(signUpSignInFlowRequest);
        }

        if (compareIssuingPolicy(event.payload.idTokenClaims, b2cPolicies.names.forgotPassword)) {
          let signUpSignInFlowRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
          };
          instance.loginRedirect(signUpSignInFlowRequest);
        }
      }

      if (event.eventType === EventType.LOGIN_FAILURE) {
        // Check for forgot password error
        if (event.error && event.error.errorMessage.includes('AADB2C90118')) {
          const resetPasswordRequest = {
            authority: b2cPolicies.authorities.forgotPassword.authority,
            scopes: [],
          };
          instance.loginRedirect(resetPasswordRequest);
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
    // eslint-disable-next-line
  }, [instance]);

  return (
    <Routes>
      <Route path="/" element={<Home />} /> {/* FIXME: Directly redirect to /home from azure */}
      <Route element={<PrivateRoute />}>
        <Route path="/home" element={<Home />} />
        <Route path="/visit" element={<MyVisit />} />
        <Route path='/activities' element={<Activities park={PARK} />}/>
        <Route path="/success" element={<SuccessSection />} />
        <Route path="/orders" element={<Orders/>}/>
        <Route path="/error" element={<ErrorSection />} />
        <Route path='/gastronomy' element={<Gastronomy/>}/>
        <Route path='/profile' element={<Profile/>}/>
        <Route path='/infoLinks' element={<InfoLinks/>}/>
        <Route path='/findUs' element={<FindUs/>}/>
        {restaurants.map((point) => (
          <Route key={point.id} path={`/gastronomy/${point.str_id}`} element={<RestaurantPoint point={point} />} />
        ))}
        <Route path='/reserved' element={<ReservedOrder/>}/>
      </Route>
    </Routes>
  );
};

const isMobileDevice = () => {
  const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  return width <= 768; 
};


const App = ({ instance }) => {
  const [isMobile, setIsMobile] = useState(isMobileDevice());

  useEffect(() => {

    document.title = process.env.REACT_APP_SITE_TITLE || 'Autogestion';
    const handleResize = () => {
      setIsMobile(isMobileDevice());
    };
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!isMobile) {
    return <GenericsError title={"Oops!"} 
            text={"Por favor, ingresa desde tu celular para continuar."}/>
  }

  return (
    <MsalProvider instance={instance}>
      <PageLayout>
        <Pages />
      </PageLayout>
    </MsalProvider>
  );
}

export default App;
