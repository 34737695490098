import { useEffect, useState } from "react";
import { useMsal } from '@azure/msal-react';
import axios from "axios";

/**
 * @returns {Array} activeItems
*/
export const useActiveItems = () => {
    const PARK_ID = process.env.REACT_APP_PARK;
    const GMM_FOODING_URL = process.env.REACT_APP_GMM_FOODING_URL;
    const {idTokenClaims : { extension_isaUUID : CLIENT_UUID }} = useMsal().instance.getActiveAccount();
    const [activeItems, setActiveItems] = useState([]);
    
    useEffect(() => {
        const fetchData = async () => {
            try{
                const { data, status } = await axios.get(
                    `https://${GMM_FOODING_URL}/users/${CLIENT_UUID}/non_validated_items`,
                    {
                        params:{
                            park_id: PARK_ID
                        }
                    }
                );
                if( 
                    ["SUCCESS", "OK", 200, "200"].includes(status) && 
                    ["SUCCESS", "ERROR"].includes(data.status)
                  ){
                    Array.isArray(data.data.items) ? setActiveItems(data.data.items) : setActiveItems([]);
                }else{
                    throw new Error("Error al obtener los items activos");
                }
            }catch(error){
                console.error(error);
                setActiveItems(null);
            }

        };
        fetchData();
    }, [PARK_ID, CLIENT_UUID])
    return activeItems;
}