import { useEffect, useState } from "react";
import styles from './ProfileImage.module.scss';

export const ProfileImage = ({ profileImageUrl, selectedImage }) => {
    const [srcImage, setSrcImage] = useState(null);

    useEffect(() => {
        if (selectedImage) { 
            const imageUrl = URL.createObjectURL(selectedImage);
            setSrcImage(imageUrl);
            return () => URL.revokeObjectURL(imageUrl); 
        } else if (profileImageUrl) { 
            setSrcImage(profileImageUrl);
        }
    }, [profileImageUrl, selectedImage]);

    return (
        <>
            {srcImage && <img className={styles.profile_image} src={srcImage} alt="profile" />}
        </>
    );
};