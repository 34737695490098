import React from 'react';
import styles from './DeveloperWatermark.module.scss'
import { DowlLogo } from './icons/DowlLogo'

function DeveloperWatermark() {


    return (
        <div className={styles.developerWatermark}>
            Powered by <DowlLogo width={32}/>
        </div>
    );
}

export default DeveloperWatermark;
