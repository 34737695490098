import styles from './InfoLinks.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { GenericButton } from '../components/generics/GenericButton';


function InfoLinks() {
    const parkName = process.env.REACT_APP_PARK_NAME;

    return (
        <section className={styles.section}>
            <h2>Enlaces útiles</h2>
            <div className={styles.container}>
                <h3>Mundo Marino</h3>
                <p>Website: <a>https://www.mundomarino.com.ar</a></p>
                <p>Mail: info@mundomarino.com.ar</p>
                <p> <FontAwesomeIcon icon={faInstagram} />@mundomarino.ar</p>
                <p> <FontAwesomeIcon icon={faWhatsapp} />(54) (02252) 43-0300</p>

            </div>
            <div className={styles.container}>
                <h3>Termas Marinas Park</h3>
                <p>Website: https://termasmarinas.com.ar/</p>
                <p>Mail: info@termasmarinas.com.ar</p>
                <p> <FontAwesomeIcon icon={faInstagram} />@termasmarinaspark</p>
                <p> <FontAwesomeIcon icon={faWhatsapp} />(02252) 42-3000</p>

            </div>
            <div className={styles.container}>
                <h3>Poseidón</h3>
                <p>Website: https://parqueposeidon.com.ar</p>
                <p>Mail: info@parqueposeidon.com.ar</p>
                <p> <FontAwesomeIcon icon={faInstagram} />@poseidonparqueacuatico</p>
                <p> <FontAwesomeIcon icon={faWhatsapp} />(02246) 42-3333</p>

            </div>

            <div className={styles.container}>

            <GenericButton
                color={'#1E1E1E'}
                backgroundColor={'#FFFFFF'}
                onClick={() => window.location.href = process.env.REACT_APP_TICKETS_URL}
            >Comprar entradas</GenericButton>

            <GenericButton
                color={'#1E1E1E'}
                backgroundColor={'#FFFFFF'}
                onClick={() => window.location.href = process.env.REACT_APP_TICKETS_URL}
            >Visitar Tienda Marina</GenericButton>
            </div>
        </section>
    );
}

export default InfoLinks;
