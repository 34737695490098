import styles from './FindUs.module.scss';
import GenericCard from '../components/generics/GenericCard'; 

function FindUs() {
    const parkName = process.env.REACT_APP_PARK_NAME;
/*     const showGastronomy = process.env.REACT_APP_SHOW_GASTRONOMY;
    const showBenefits = process.env.REACT_APP_SHOW_BENEFITS; */

    let cardContents = [
        {
            title: "Mundo Marino",
            description: `Av X 157, San Clemente del Tuyú, Buenos Aires.`,
            image: `images/mmMapLocation.jpg`,
            mapUrl:'https://www.google.com/maps/place/Mundo+Marino/@-36.3296158,-56.7557201,14z/data=!4m6!3m5!1s0x959c0336824fcf97:0xc22e2197ea1ff71c!8m2!3d-36.3387428!4d-56.7503342!16s%2Fm%2F0277tcx?entry=ttu'
        },
        {
            title: "Poseidón",
            description: `RP11 324 500, Santa Teresita, Provincia de Buenos Aires.`,
            image: `images/poseidonMapLocation.jpg`,
            mapUrl:'https://www.google.com/maps/place/Poseid%C3%B3n/@-36.5329255,-56.7162652,15z/data=!4m2!3m1!1s0x0:0xa8b6d741bd3b3d70?sa=X&ved=2ahUKEwjwzZPc9MyDAxUWppUCHQpzA1wQ_BJ6BAh0EAA'
        },
        {
            title: "Termas Marinas Park",
            description: `Av. Naval 0, B7105 San Clemente del Tuyu, Provincia de Buenos Aires.`,
            image: `images/tmMapLocation.jpg`,
            mapUrl:'https://www.google.com/maps/place/Termas+Marinas+Park/@-36.3081502,-56.7711209,19z/data=!4m6!3m5!1s0x959c0336b56b068d:0x26af38ebd28bcbd3!8m2!3d-36.3084783!4d-56.7709408!16s%2Fg%2F11bx893zbn?entry=ttu'
        }
    ];

       const handleCardClick = (url) => {
        window.open(url, '_blank');
    }

    return (
        <section className={styles.section}>
            <h2>Como llegar</h2>
            <div className={styles.cardContainer}>
                {cardContents.map((content, index) => {
                    return (
                    <div className={styles.card} onClick={() => handleCardClick(content.mapUrl)} >
                        <GenericCard
                            key={index}
                            title={content.title}
                            description={content.description}
                            image={content.image}
                    
                        />      
                    </div>   
                    )               
                })}
            </div>
        </section>
    );
}

export default FindUs;
