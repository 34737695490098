import { CenteredLayout } from './CenteredLayout';

export const GenericsError = ({title, text, children, style}) => {
    const errorImage = process.env.REACT_APP_BLOB_ROOT_FOLDER_URL + `/error.svg`;
    return (
        <CenteredLayout style={style}>
            <img src={errorImage} alt="" />
            <h2>{title}</h2>
            <p>{text}</p>
            {children}
        </CenteredLayout>
    );
}