import axios from "axios";
import { useEffect, useState } from "react";

export const useRestaurantPoints = (park) => {
    const [sellingPoints, setSellingPoints] = useState([]);
    const GMM_FOODING_URL = process.env.REACT_APP_GMM_FOODING_URL;
    useEffect(() => {
        const fetchSellingPoints = async (parkId) => {
            try{
                const {data : {data : {points}}} = await axios.get(`https://${GMM_FOODING_URL}/points/`,
                {
                    params:{
                        park_id: parkId
                    }
                });
                setSellingPoints(points);
            }catch(err){
                console.log(err);
            }
        }
        fetchSellingPoints(park);
    }, [park]);
    return sellingPoints;
}