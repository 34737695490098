import { ErrorScreen } from './ErrorScreen';

export const NotAvailableProducts = () => {
    return (
        <ErrorScreen title='No hay ordenes'
            text='Podes realizar pedidos desde los puntos gastronomicos del parque.'
            style={{ 
                marginTop: '0', 
                paddinTop: '0',
                textAlign:'center'
            }} />
    );
};
