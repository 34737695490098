import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GenericsSuccess } from  '../components/generics/GenericsSuccess';
import { GenericButton } from '../components/generics/GenericButton';
import styles from './ReservedOrder.module.scss';

export const ReservedOrder = () => {
    const location = useLocation();
    const [code, setCode] = useState('');
    const [point, setPoint] = useState('');
    const navigate = useNavigate();
    useEffect(()=> {
        setCode(location.state.code)
        setPoint(location.state.point)
        console.log(code);
        console.log(location.state)
    }, [])
    const navigateToGastronomy = () => {
        navigate('/gastronomy');
    }
    return (
        <div className={styles.reservedOrderParent}>
            <GenericsSuccess text={`Ya reservaste tu pedido para ${point}!`}>
                <div className={styles.reservedOrder}>
                    <p>Para retirarlo, acercate al punto de entrega del local.</p>
                    <div className={styles.bottom_screen}>
                        <p>Tu código de entrega es</p>
                        <span className={styles.code}>{code}</span>
                        <GenericButton onClick={navigateToGastronomy}
                            backgroundColor={process.env.REACT_APP_WHITE_COLOR}>
                            <span>
                                Volver a gastronomia
                            </span>
                        </GenericButton>
                    </div>
                </div>
            </GenericsSuccess>
        </div>
    );
}