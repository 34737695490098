import axios from 'axios';
import styles from './Activities.module.scss';

import Loading from '../components/Loading';

import { useEffect, useState } from 'react';
import { Checkbox } from '../components/Checkbox';
import { useActivities } from '../hooks/useActivities.js';
import { useActiveVisit } from '../hooks/useActiveVisit.js';
import { useNavigate } from 'react-router';

const GMM_URL = process.env.REACT_APP_GMM_URL;
export const Activities = ({park}) => {
    const navigate = useNavigate();
    const { activitiesOnPark, categories } = useActivities(park);
    const activeVisit = useActiveVisit(activitiesOnPark);
    const [checkedActivities, setCheckedActivities] = useState({});
    const [ isLoading, setLoading ] = useState(true);

    /**
     * Check activities based on active visit
    */
    useEffect(() => {
        const initialCheckedActivities = {};
        if(activeVisit == null) return;
        if(activeVisit && activeVisit.hasOwnProperty('activities')){
            Object.values(activitiesOnPark)
                .forEach(category => {
                    category.forEach(parkActivity => {
                        initialCheckedActivities[parkActivity.id] = activeVisit.activities
                            .some(activityObj => activityObj.activity_id['$oid'] == parkActivity.id);
                    })
                });
    
            setCheckedActivities(initialCheckedActivities);
            setLoading(false);
        }else{
            return;
        }

    }, [activeVisit, activitiesOnPark])

    const toggleVisit = async (visitID, activityId) => {
        try{
            await axios.post(`https://${GMM_URL}/attendants/visit/${visitID}/toggle_activity?activity_id=${activityId}`);
        } catch (err){
            console.log(err);
            navigate('/error');
        }
    }

    const handleCheckboxChange = async (visit, activityId) => {

        setCheckedActivities(prevCheckedActivities => ({
            ...prevCheckedActivities,
            [activityId]: !prevCheckedActivities[activityId]
        }));
        await toggleVisit(visit.id, activityId);
    }

    const parkName = process.env.REACT_APP_PARK_NAME

    return (
        <div>
        <h3 className={styles.title}>Visitando {parkName}</h3>
        <section className={styles.section}>
            <h4>Actividades</h4>
            {isLoading && <Loading />}
            {!isLoading &&
                <p>{categories.length > 0 ? 'Marcalas cuando las hayas completado.' : 'No hay actividades en este parque.'}</p>
            }
            {!isLoading && categories.length > 0 && categories.map(category => (
                <div key={category}>
                    <h5 className={styles.category} id={category}>{category.charAt(0).toUpperCase() + category.slice(1).toLowerCase()}</h5>
                    <div className={styles.categories_container}>
                        {
                            activitiesOnPark[category].map(activity => (
                                <Checkbox 
                                textLabel={activity.name}
                                description={activity.description}
                                key={activity.id}
                                id={activity.id}
                                checked={ checkedActivities[activity.id] ?? false}
                                onChange={ () => handleCheckboxChange(activeVisit, activity.id)}
                                />
                            ))
                        }
                    </div>
                </div>
            ))}
        </section>

        </div>
    )
}
