import { CenteredLayout } from "./CenteredLayout";
import Loading from "../Loading";
export const GenericsProcessing = ({children}) => {
    const processingImage = process.env.REACT_APP_BLOB_ROOT_FOLDER_URL + `/loading.svg`;

    return (
        <CenteredLayout>
            <Loading/>
            {children}
            <img src={processingImage} alt="Processing image" srcset="" />
        </CenteredLayout>
    );
}