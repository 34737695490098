// GenericCard.js
import styles from './GenericCard.module.scss';
import { Link } from 'react-router-dom';

function GenericCard({ title, description, image, link }) {
    return (
        <div className={styles.card}>
            <div className={styles.firstContainer}>
                <img src={image} alt="Card" />
            </div>
            <div className={styles.secondContainer}>
                <h3>{title}</h3>
                <p>{description}</p>
              
                {link ? (
                    <Link to={link} style={{ backgroundColor: process.env.REACT_APP_PRIMARY_COLOR }}>
                        Ir a {title}
                    </Link>
                ) : null}
            </div>
        </div>
    );
}

export default GenericCard;
