import styles from './MyVisit.module.scss';
import GenericCard from '../components/generics/GenericCard';

function MyVisit() {
    const parkName = process.env.REACT_APP_PARK_NAME;
    const showGastronomy = process.env.REACT_APP_SHOW_GASTRONOMY;
    const showBenefits = process.env.REACT_APP_SHOW_BENEFITS;

    let cardContents = [
        {
            title: "Actividades",
            description: `Conocé todo lo que podés hacer en ${parkName}.`,
            image: `${process.env.REACT_APP_BLOB_ROOT_FOLDER_URL}/activityImage.svg`,
            link: '/activities'
        }
    ];

    if (showGastronomy === 'true') {
        cardContents.push({
            title: "Gastronomía",
            description: "Gestioná tus pedidos ya pagos desde el parque.",
            image: `${process.env.REACT_APP_BLOB_ROOT_FOLDER_URL}/foodImage.svg`,
            link: '/gastronomy'
        });
    }

    if (showBenefits === 'true') {
        cardContents.push({
            title: "Beneficios",
            description: "Descubrí los beneficios exclusivos que tenés dentro del parque.",
            image: `${process.env.REACT_APP_BLOB_ROOT_FOLDER_URL}/benefitImage.svg`,
            link: '/benefits'
        });
    }

    return (
        <section className={styles.section}>
            <h2>Visitando {parkName}</h2>
            <p>Acciones dentro del parque</p>
            <div className={styles.cardContainer}>
                {cardContents.map((content, index) => {
                    return <GenericCard
                        key={index}
                        title={content.title}
                        description={content.description}
                        image={content.image}
                        link={content.link}
                    />                        
                })}
            </div>
        </section>
    );
}

export default MyVisit;
