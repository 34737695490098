import { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import styles from './Gastronomy.module.scss';

import Loading from '../components/Loading';
import { APIError } from '../components/APIError';
import { CenteredLayout } from '../components/generics/CenteredLayout';
import { GenericButton } from "../components/generics/GenericButton";
import { NotAvailableProducts } from '../components/NotAvailableProducts';

import { useRestaurantPoints } from '../hooks/useSellingPoints';
import { useActiveItems } from '../hooks/useActiveItems';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';


export const Gastronomy = () => {
    const sellingPoints = useRestaurantPoints(process.env.REACT_APP_PARK)
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const client_uuid = activeAccount.idTokenClaims.extension_isaUUID;
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [hasAvailableItems, setHasAvailableItems] = useState(false);
    const [activeOrders, setActiveOrders] = useState([]);
    const [hasActiveOrders, setHasActiveOrders] = useState(false);
    const items = useActiveItems();

    useEffect(()=> {
        const getActiveOrders = async () => {
            const response = await axios.get(`https://${process.env.REACT_APP_GMM_FOODING_URL}/orders/client/${client_uuid}/pending`);
            const {data: {data: {orders}}} = response
            setActiveOrders(orders)
            if (orders.length > 0) {
                setHasActiveOrders(true)
            }
        }
        getActiveOrders();
    }, [])


    useEffect(() => {
        
        if( !Array.isArray(items)){
            setError(true);
        }
        if(items && items.length > 0) {
            setHasAvailableItems(true);
        }
        setLoading(false);
    }, [items])

    const statusMapping = {
        'IN_PROGRESS': 'PREPARACION',
        'CREATED': 'NUEVO',
        'PREPARED': 'LISTO PARA RETIRAR'
    }

    return (
        <section className={styles.section}>
            <h1>Gastronomia</h1>
            {loading && (<CenteredLayout children={<Loading/>} />)}
            {!loading && !error && (
                <p>Realiza los pedidos de tus items disponibles en la seccion gastronomica del parque.</p>
                )}
            { !loading && error && (
                <APIError/>
            )}
            {!loading && hasActiveOrders && (
            <section className={styles.active_orders}>
                <h2>Pedidos activos</h2>
                <div className={styles.card_active_order}>
                    {activeOrders.map((order) => (
                        <div className={styles.incoming_order} key={order._id["$oid"]}>
                            <div className={styles.active_order_column}>
                                <p>
                                    {sellingPoints.find(point => point.str_id === order.food_point_id)?.name}
                                </p>
                                <p>
                                    Código: {order.verification_code}
                                </p>
                            </div>
                            <div className={`${styles.order_status}  ${order.status !== 'PREPARED' ? styles.new : styles.ready}`}>
                                <p>
                                    {statusMapping[order.status]}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
            )}
            { !loading && !error && hasAvailableItems && (
                <>
                    <h2>Tenes disponible:</h2>
                    {items.map((item) => (
                    <section key={item.id} className={styles.item}>
                        <img src='images/defaultFood.svg' alt="" />
                        <span className={styles.details}>
                            <span className={styles.qty_name}>
                                <span className={styles.qty}>
                                    <strong>x{item.pending_qty}</strong>
                                </span>
                                <span className={styles.name}>
                                    {item.info.name}
                                </span>
                            </span>
                            <span className={styles.selling_point}>
                                Canjeable en {item.info.external_food_point.map(point_id => sellingPoints.find(point => point.str_id === point_id)?.name).join(', ')}
                            </span>
                        </span>
                    </section>   
                    ))}
                </>
            ) }
            { !loading && !error && hasAvailableItems && sellingPoints && (
                <>
                <h2>Puntos gastronómicos</h2>
                {sellingPoints.map((point) => (
                    items.some((item) => item.info.external_food_point.includes(point.str_id)) &&
                    point.active &&
                    <div className={styles.pos_button}>
                        <GenericButton className={styles.pos_button}>
                            <Link key={point.str_id} to={`${point.str_id}`}>
                                <span className={styles.pos_name}>
                                    <h3>
                                        {point.name}
                                    </h3>
                                </span>
                            </Link>
                        </GenericButton>
                    </div>
                ))}
                </>
            )}
            { !loading && !hasAvailableItems && !error && (
                <NotAvailableProducts/>
            )
            }
        </section>
    )
};